import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { useNavigate } from "react-router-dom";
import CommonLoader from "../../../loader/CommonLoader";
import CommonHeader from "../common-components/CommonHeader";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import NoDataFoundPage from "../common-components/NoDataFound";
import CustomModal from "../common-components/customModal";
import { hasPermission } from "../../../helperPermissionEditor";
import { LoginContext } from "../../../App";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./pages.css";

const PhoneListing = () => {
  const { user } = useContext(LoginContext);
  const navigate = useNavigate();

  const [phones, setPhones] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedPhoneId, setSelectedPhoneId] = useState(null);
  const [newPhone, setNewPhone] = useState({ code: "", status: "", freelancer_id: "" });
  const [deletePhone, setDeletePhone] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [clientOptions, setClientOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [perPage, setPerPage] = useState(10);

  const fetchPhones = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/phones?page=${page}`);
      setPhones(response.data.data || []);
      setTotalPages(response.data.pagination?.last_page || 1);
      setPerPage(response.data.pagination?.per_page || 10);
    } catch (error) {
      console.error("Error fetching phones:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPhones();
    const intervalId = setInterval(fetchPhones, 300000);
    return () => clearInterval(intervalId);
  }, []);

  const openModal = async (isEdit = false, index = null) => {
    setShowModal(true);

    if (isEdit && index !== null) {
      setIsEditing(true);
      setSelectedPhoneId(phones[index]?.id);
      const { code, status, freelancer_id } = phones[index];
      setNewPhone({ code, status, freelancer_id });
    } else {
      setIsEditing(false);
      setNewPhone({ code: "", status: "0", freelancer_id: "" });
    }

    try {
      const response = await axiosInstance.get(
        isEdit && index !== null
          ? `/api/phones/${phones[index]?.id}/edit`
          : `/api/phones/create`
      );

      const { freelancers, "status-dropdown": statusDropdown, phone } = response.data;

      const options = freelancers.map((client) => ({
        id: client.id,
        name: client.name,
      }));
      setClientOptions(options || []);

      const statusOptions = Object.entries(statusDropdown).map(([key, value]) => ({
        id: key,
        name: value,
      }));
      setStatusOptions(statusOptions);

      if (isEdit && phone) {
        const { code, status, freelancer_id } = phone;
        setNewPhone({ code, status, freelancer_id });
      }
    } catch (error) {
      console.error("Error fetching data for the modal:", error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setIsEditing(false);
    setNewPhone({ code: "", status: "", freelancer_id: "" });
  };

  const handleSavePhone = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const endpoint = isEditing ? `/api/phones/${selectedPhoneId}` : `/api/phones`;
      const method = isEditing ? axiosInstance.put : axiosInstance.post;
      await method(endpoint, newPhone);
      toast.success(`Phone ${isEditing ? "updated" : "created"} successfully`);
      closeModal();
      fetchPhones();
    } catch (error) {
      console.error("Error saving phone:", error);
      toast.error(error.response?.data?.message || "Failed to save phone");
    }
    setIsLoading(false);
  };

  const handleDelete = async (phoneId) => {
    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/phones/${phoneId}`);
      setPhones(phones.filter((phone) => phone.id !== phoneId));
      toast.success("Phone deleted successfully");
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.clear();
        navigate("/");
      } else {
        toast.error("Error deleting phone");
      }
      console.error("Error deleting phone:", error);
    }
    setIsLoading(false);
  };

  const handleInputChange = (event, key) => {
    setNewPhone((prev) => ({ ...prev, [key]: event.target.value }));
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    fetchPhones(page);
  };

  const filteredPhones = phones.filter((phone) =>
    phone.code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="phoneLists userLists">
      <CommonHeader
        dashboardName="Phone"
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => openModal(false)}
        user={user}
      />

      <div className="mt-3">
        <table className="user-table mt-4">
          <thead>
            <tr>
              <th>SR #.</th>
              <th>Code</th>
              <th>Status</th>
              <th>Freelancer</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredPhones.length > 0 ? (
              filteredPhones.map((phone, index) => (
                <tr key={phone.id}>
                  <td>{(currentPage - 1) * perPage + index + 1}</td>
                  <td>{phone.code}</td>
                  <td>{phone.status}</td>
                  <td>{phone.freelancer || "N/A"}</td>
                  <td>{phone.created_at}</td>
                  <td>
                    <button
                      data-tooltip-id="Edit-button"
                      className="edit me-2"
                      onClick={() => openModal(true, index)}
                      style={{
                        padding: "4px 10px",
                        fontSize: "22px",
                      }}
                      disabled={!hasPermission(user?.permissions, "update-phone")}
                    >
                      <TbEdit />
                    </button>
                    <ReactTooltip
                      id="Edit-button"
                      place="bottom"
                      content="Edit Phone"
                    />
                    <button
                      data-tooltip-id="Delete-button"
                      className="delete edit"
                      style={{
                        background: "red",
                        color: "white",
                        fontSize: "22px",
                        padding: "4px 10px",
                      }}
                      onClick={() => handleDelete(phone.id)}
                      disabled={!hasPermission(user?.permissions, "delete-phone")}
                    >
                      <MdDelete />
                    </button>
                    <ReactTooltip
                      id="Delete-button"
                      place="bottom"
                      content="Delete Phone"
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">
                  <NoDataFoundPage />
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {totalPages > 1 && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        )}
      </div>

      <CustomModal
        show={showModal}
        handleClose={closeModal}
        headerContent={<h4>{isEditing ? "Edit Phone" : "Create Phone"}</h4>}
        bodyContent={
          <Form onSubmit={handleSavePhone}>
            <Form.Group>
              <Form.Control
                type="text"
                value={newPhone.code}
                onChange={(e) => handleInputChange(e, "code")}
                placeholder="Phone Code"
                className="creatuserListing mb-3"
              />
            </Form.Group>
            <Form.Group>
              <Form.Select
                className="mb-3"
                value={newPhone.freelancer_id}
                onChange={(e) => handleInputChange(e, "freelancer_id")}
              >
                <option>Select Freelancer</option>
                {clientOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Select
                className="mb-3"
                value={newPhone.status}
                onChange={(e) => handleInputChange(e, "status")}
              >
                <option>Select Status</option>
                {statusOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
        }
        footerContent={
          <>
            <Button onClick={handleSavePhone} className="creatrUserSubmit" variant="primary">
              {isEditing ? "Update" : "Create"}
            </Button>
            <Button onClick={closeModal} variant="secondary"
              className="creatrUserClose">Close</Button>
          </>
        }
      />

      {isLoading && <CommonLoader />}
      <Toaster />
    </div>
  );
};

export default PhoneListing;
